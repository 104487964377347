import React from "react";
import Artykul from "../components/artykul";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/system crm.jpg";

export const query = graphql`
  query {
    zdjecie1: file(relativePath: { eq: "system-crm-wyglad-systemu.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(relativePath: { eq: "system-crm-saas.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(relativePath: { eq: "system-crm-kalendarz.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(relativePath: { eq: "system-crm-raporty.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(relativePath: { eq: "system-crm-zadania.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie6: file(relativePath: { eq: "system-crm-proces-sprzedazy.png" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie7: file(relativePath: { eq: "lejek.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie8: file(relativePath: { eq: "system-crm-Baner-testuj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const SystemCRM = ({ data }) => {
  return (
    <Artykul
      title="Co to jest system CRM i do czego służy?"
      articleImage={obrazekArtykulu}
      keywords={["system CRM"]}
      articleImageAlt="system CRM"
      metaTitle="System CRM • Co to jest i do czego służy?"
      metaDescription="✅ System CRM • Wybierz proste i mobilne rozwiązanie • CRM dla firm • QuickCRM - 30 dni za darmo!"
    >
 

<br />

<h2>System CRM – jeden skrót, dwa aspekty</h2>
<p>Aby zrozumieć, czym jest system CRM warto zacząć of wyjaśnienia znaczenia trzyliterowego skrótu:
CRM, czyli customer relationship management (z j. ang. zarządzanie relacjami z klientami) to koncepcja, która zakłada, że <strong>jednym z najważniejszych celów przedsiębiorstwa jest zaspokojenie potrzeb jego klientów</strong>.
<br />
Na tej podstawie wyrasta strategia, czy też filozofia CRM, która skupia się na takim organizowaniu relacji z klientem, aby osiągać możliwie najwyższą satysfakcję ze współpracy. 
</p>
<br />
<p>Poniżej znajdziesz przydatne informacje o systemach CRM, a także odpowiedzi na pytania:
<li> Co to jest system CRM?</li>
<li> Do czego służy?</li>
<li> Jaka jest historia CRM?</li>
<li> Jakie procesy obsługuje?</li>
<li> Czym jest CRM online?</li>
<li> Jak wybrać CRM dla małej firmy?</li>
<br />
</p>
<h3>System CRM – co to jest?</h3>
<br />
<p>Do realizacji filozofii CRM wykorzystuje się najczęściej system informatyczny, czyli system CRM. Jego zadaniem jest wsparcie procesu zarządzania relacjami z klientami. Ułatwia planowanie i koordynowanie pracy działu handlowego i rejestruje wszystkie kontakty firmy z otoczeniem. 
  <br />
<strong>Wykorzystanie systemu CRM pozwala uporządkować rozproszoną pracę umysłową człowieka i w ten sposób pozyskiwać wiedzę o jej efektywności i o możliwych sposobach na jej usprawnienie.</strong></p>
<br />
<h3>CRM kiedyś i teraz – historia CRM</h3>
<p>Początki systemów CRM miały miejsce w latach 90. XX wieku w USA. Systemy te były ówcześnie wyłącznie stacjonarne i dość toporne dla użytkowników. Bardzo szybko jednak rozwinęły się, a wraz z rozwojem sieci i urządzeń mobilnych pojawił się model SaaS (oprogramowanie, jako usługa), który pozwala na obsługę systemu z poziomu przeglądarki bez konieczności instalowania systemu CRM na komputerze. To tzw. System CRM w Saas-ie lub CRM online. </p>

<Img
          style={{
            width: "0 auto",
            margin: "0 auto",
            maxWidth: 300
          }}
          title="System CRM - SaaS"
          alt="system CRM - saas"
          fluid={data.zdjecie2.childImageSharp.fluid}
        />
        <br />
        <br />

<p>
System CRM online to dobre rozwiązanie dla nowoczesnego przedsiębiorstwa. Jego główną zaletą jest to, że pozwala Ci korzystać z niego na każdym urządzeniu z dostępem do Internetu. Ułatwia to dzielenie się informacjami wśród pracowników i wspiera bezpieczeństwo danych. Nie może się zdarzyć sytuacja, w której utracisz je na skutek awarii dysku, bo będą przechowywane w modelu cloud (chmura plików).
<br />
<br />
System CRM w chmurze pozwala bardzo szybko rozpocząć pracę z nim bez długiej konfiguracji sprzętowej czy instalowania na wielu urządzeniach. Najczęściej wystarczy kilka chwil, żeby wypróbować system CRM.
</p>
<br />
<h3>Komu dedykowane jest to rozwiązanie?</h3>
<p>
System klasy CRM sprawdzi się zarówno w małym przedsiębiorstwie, jak i dużej firmie. Jednym z większych problemów każdego z tych podmiotów jest chaos spowodowany brakiem sposobu na porządkowanie danych o kliencie.
<br />
<br />
<strong>Jeśli chcesz, aby Twój dział handlowy mógł działać sprawniej i szybciej, warto zastosować system CRM. </strong>
 </p>
<br />
<h3>Korzyści z wprowadzenia systemu CRM w firmie</h3>
<p>
System CRM sprawi, że Twoja firma będzie skuteczniej obsługiwać swoich klientów i będą oni bardziej usatysfakcjonowani ze współpracy. Jak? Dając Ci narzędzie do obsługi danych, Twoi handlowcy będą dużo lepiej przygotowani do reagowania na potrzeby klienta. W ten sposób wzrasta szansa, że Ci będą chcieli przedłużyć współpracę.
</p>

<Img
          style={{
            width: "0 auto",
            margin: "0 auto",
            maxWidth: 600
          }}
          title="System CRM - kalendarz"
          alt="system CRM - kalendarz"
          fluid={data.zdjecie3.childImageSharp.fluid}
        />
        <br />
<h3>Co może dać Ci system CRM dla firm?</h3>
<p>
Może rozwiązać problemy, które napotyka wiele przedsiębiorstw:
<br />
<ol>
<br />
<li><strong>Nieobecności pracowników.</strong></li>

Wyobraź sobie, że pracownik, który miał przeprowadzić dzisiaj spotkanie z klientem, zachorował i nie pojawił się w pracy. Musisz szybko znaleźć zastępstwo, bo klient już do Ciebie jedzie. 
<br />

Co robisz? 
<br />
<br />
Bez odpowiedniego narzędzia CRM jesteś bezradny – wszystkie informacje o Waszej dotychczasowej współpracy, potrzebach klienta i jego możliwościach finansowych znajdują się u nieobecnego handlowca. System CRM sprawia, że wszystkie informacje, niezbędne do prowadzenia spotkania są bezpieczne i dostępne dla każdej uprawnionej osoby.
 <br />
<br />
<li><strong>Monitorowanie procesu sprzedaży</strong></li>

Wielu menedżerów sprzedaży ma problem z określeniem, jakie aktywności zostały wykonane względem danego kontrahenta i danej szansy sprzedażowej. Brak narzędzia, które porządkuje pracę umysłową handlowców sprawia, że jest ona trudna w ewaluacji.
<br />
System CRM jest wyposażony w kalendarz, bazę kontrahentów i funkcję szans sprzedaży, które porządkują dane o klientach, działaniach handlowców i postępach w procesie sprzedaży. Pozwala go monitorować i podejmować najlepsze decyzje sprzedażowe.
<br />
<br />
<li><strong>Uciążliwe zbieranie danych</strong></li>

Kolejnym z problemów, których doświadczają handlowcy, jest uciążliwe i czasochłonne zbieranie danych niezbędnych do sporządzania raportów. Poszczególni pracownicy mają swoje własne sposoby na notowanie informacji, więc ujednolicenie tych danych może przyprawić o ból głowy.
</ol>
<br />
Jedną z zalet systemu CRM jest fakt, że to rozwiązanie pozwala na automatyczne zestawienie informacji o działaniach handlowych. Szereg raportów, dotyczących procesu sprzedaży daje szansę na szybkie identyfikowanie zagrożeń i reagowanie na zmieniającą się dynamicznie sytuację.

</p>
<br />
<p>
Oto najbardziej <strong>przydane raporty w systemie CRM:</strong>
<br />
<br />
<li> <strong>Lejek szans sprzedaży</strong> ma za zadanie monitorowanie kampanii sprzedażowych. Pozwala na oszacowanie liczby szans i zysków. Pomaga zweryfikować prawdopodobieństwo osiągnięcia targetów.</li>
<li> <strong>Konwersja lejka</strong> informuje o tym, jaki procent szans przechodzi między etapami i daje wiedzę o tzw. wąskich gardłach, czyli o częściach procesu, w których spada skuteczność Twoich handlowców.</li>
<li> <strong>Przyczyny utraty</strong> informują o tym, dlaczego szanse zostały przegrane. W momencie oznaczenia szansy jako utraconej system prosi handlowca o podanie powodu. Raport ten ukazuje najczęściej podawane przez nich przyczyny.</li>
<li> <strong>Aktywność handlowców</strong> umożliwia przeanalizowanie typów podejmowanych przez pracowników działań. Dostarcza informacji, jakiego rodzaju kroki podjęli handlowcy, aby osiągnąć cel sprzedażowy. Tą wiedzę można wykorzystać, organizując ich pracę w przyszłości.</li>
</p>
<br />
<Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="System CRM - raporty"
          alt="system CRM - raporty"
          fluid={data.zdjecie4.childImageSharp.fluid}
        />
<br />
<h3>Funkcje systemu CRM</h3>
<p>
Oprócz wspierania procesów sprzedażowych, system CRM może poprawić efektywność pracowników. Pozwala skutecznie zarządzać, planować i koordynować ich pracą. Organizacja pracy umysłowej w ramach CRM pozwala na szybsze i łatwiejsze realizowanie celów i szybkie identyfikowanie zagrożeń.</p>

<p>Wśród najważniejszych funkcji systemu CRM znajdują się:
<li> <Link to="/sprzedaz-w-crm/">Zarządzanie sprzedażą</Link> – szanse sprzedaży w CRM pozwalają na obsługę wielu procesów sprzedażowych.</li>
<li> <Link to="/raporty/">Raporty</Link> – system CRM sam gromadzi dane i generuje raporty automatycznie.</li>
<li> <Link to="/kartoteki-kontrahentow/">Kartoteki kontrahentów</Link> – uporządkowany zbiór danych w CRM pozwala na stały dostęp do wiedzy o klientach i zarządzanie nią.</li>
<li> <Link to="/kalendarz-i-zadania/">Planowanie zadań</Link> – interaktywne narzędzie, które służy do planowania i koordynacji pracy działu handlowego.</li>
</p>
<Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="System CRM - raporty"
          alt="system CRM - raporty"
          fluid={data.zdjecie5.childImageSharp.fluid}
        />
        <br />
<h3>CRM dla małej firmy? Jak wybrać?</h3>
<p>Wybór systemu CRM dla małej firmy stanowi często duże wyzwanie. Ogrom dostępnych na rynku rozwiązań, które celują w małe przedsiębiorstwa nie sprzyja podejmowaniu szybkiej decyzji. Ważne jest jednak to, żeby dany system CRM pozwalał na obsługę procesów, które występują właśnie w Twojej firmie.</p>
<br />
<h3>Procesy wspierane przez system CRM</h3>
<p>
Rozwiązania CRM mogą ułatwić planowanie i realizowanie różnego rodzaju procesów, a należą do nich:
<br />
<br />
<li> <strong>Proces pozyskiwania kontraktów (tzw. sprzedaż projektowa)</strong>, w którym przedmiotem sprzedaży nie jest gotowy produkt, a rozwiązanie, które jest dostosowywane do wymagań klienta. Jest on długofalowy i czasochłonny oraz wymaga od handlowca szczególnej wiedzy eksperckiej.</li>
<li> <strong>Budowa kanału sprzedaży pośredniej</strong>, która polega na przekazywaniu produktów pomiędzy producentem, a odbiorcą z wykorzystaniem podmiotów trzecich. Proces ten zakłada dotarcie do pośrednika, a nie do klienta końcowego.</li> 
<li> <strong>Budowa sieci stałych odbiorców końcowych</strong>, mającej za zadanie pozyskanie i utrzymywanie współpracy z kontrahentem końcowym, który będzie pobierał produkt bezpośrednio od producenta.</li>
<li> <strong>Proces rozwoju relacji z klientem</strong>, czyli tzw. ponowienia, cross–selling i up–selling. Cross-selling to technika, w której klientowi proponuje się dobra komplementarne, czyli takie które uzupełnią już zakupione. Natomiast up–selling to technika sprzedaży, w ramach której proponuje się klientowi produkty lub usługi o wyższym standardzie czy wersji.</li>
<li> <strong>Wprowadzanie produktów</strong>, czyli obsługa wejścia na rynek nowej usługi czy produktu;</li>
<li> <strong>Proces utrzymania współpracy z klientem</strong>, polegający na utrzymywaniu pozytywnych relacji z kontrahentem.</li>
<br />
Oto przykładowa kampania sprzedaży projektowej w systemie CRM:</p>
<br />
<Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="System CRM - proces sprzedaży"
          alt="system CRM - proces sprzedaży"
          fluid={data.zdjecie6.childImageSharp.fluid}
        />
<br />
<h3>Jak zacząć?</h3>
<p>Jeśli zainteresował Cię ten temat i chciałbyś zastosować to rozwiązanie w swoim zespole – skontaktuj się z nami. Oferujemy w pełni funkcjonalny system, który będzie można testować za darmo przez 30 dni.</p>



<br />

  
      <Link to="/demo-crm/">
        <Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="Testuj System CRM"
          alt="Testuj system CRM"
          fluid={data.zdjecie8.childImageSharp.fluid}
        />
      </Link>
    </Artykul>
  );
};

export default SystemCRM;
